@import "mixin";
@import "animate";

$offcanvas-width: 260px !default;
$color-base: #333;
$color-red: #c33;

@charset "UTF-8";
@include keyframes(animationOpacity){
	0% {
		opacity:0;
	}
	30% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
body{
	@include animation(animationOpacity 1.2s ease 0.15s);
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "HelveticaNeue",Helvetica,Arial,sans-serif;
	color: $color-base;
  a{
    color: $color-base;
  }
  a:hover{
    color: $color-base;
  }
  a.disable {
    pointer-events: none;
    cursor: default;
  }
}
.header{
	position: fixed;
	width: 100vw;
	z-index: 10000;
	background: #fff;
}
.gnav{
	height: 60px;
	z-index: 1000;
	margin:0 auto;
	.logo{
		width: 260px;
		img{
			width: 260px;
		}
	}
	@include mq('min', 'lg') {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.menu-trigger,.overlay{
			display: none !important;
		}
		ul{
			display: flex;
			justify-content: space-between;
			margin-bottom: 0;
			li{
				font-size: .95rem;
				a{
					padding: 10px 6px 5px;
					display: inline-block;
				}
			}
		}
	}
	@include mq('min', 'xl') {
		ul{
			li{
				a{
					padding: 10px 15px 5px;
				}
			}
		}
	}
	@include mq('max', 'lg') {
		.menu-trigger {
			display: inline-block;
			width: 24px;
			height: 19px;
			vertical-align: middle;
			cursor: pointer;
			position: fixed;
			top: 20px;
			right: 20px;
			z-index: 1002;
			transform: translateX(0);
			span {
				display: inline-block;
				box-sizing: border-box;
				position: absolute;
				left: 0;
				width: 100%;
				height: 3px;
				background-color: #707070;
				transition: all .5s;
				&:nth-of-type(1) {
					top: 0;
				}
				&:nth-of-type(2) {
					top: 8px;
				}
				&:nth-of-type(3) {
					bottom: 0;
				}
			}
			&.active {
				transform: translateX(0);
				z-index: 2000;
				span {
					background-color: $color-base !important;
					&:nth-of-type(1) {
						transform: translateY(8px) rotate(-45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						transform: translateY(-8px) rotate(45deg);
					}
				}
			}
		}
		.logo{
			top:14px;
			left:20px;
			margin: 0 auto;
			position: absolute;
		}
		ul {
	  	width: 100vw;
	    height: 100%;
	    position: fixed;
	    left: 0;
			z-index: 1005;
	    transform: translate(-100vw);
	    transition: all .5s;
	    overflow-x:hidden !important;
			opacity: 0;
	    margin: 60px 0 5px;
			padding-top: 15px;
			background: #fff;

	    &.open {
	      transform: translateX(0);
	      overflow:hidden;
	      box-sizing:border-box;
				opacity: 1;
		    li {
					opacity: 1;
				}
	    }
			li {
				color: $color-base;
				opacity: 0;
				padding: 4px 15px;
				background: #fff;
	      a {
	        color: $color-base;
	        display: block;
	        text-decoration: none;
		      padding: 5px 25px;
					&:hover{
						// color: #fff;
		      }
	      }
				dl{
					padding: 30px 25px 8px;
					line-height: 1.95;
					dt{
						margin-bottom: .3rem;
					}
					a{
						padding: 0;
					}
				}
	    }
		}
	  .overlay {
	    content: "";
	    display: block;
	    width: 0;
	    height: 0;
	    background-color: #fff;
	    position: fixed;
	    top: 0;
	    left: 0;
	    z-index: 1001;
	    opacity: 0;
	    transition: opacity .5s;
	    top: 70px;
	    &.open {
	      width: 100%;
	      height: 100%;
	      opacity: 1;
	    }
	  }
	}
}

footer{
  font-size: .7rem;
  margin: 3rem 0 1rem;
	&>div{
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}
	img{
		width: 71px
	}
}
.to-page-top {
	@include mq('min', 'xs') {
	  z-index: 1100;
	  position: fixed;
		bottom: 20px;
		right: 20px;
		width: 50px;
		height: 50px;
		text-align: center;
		background: #fff;
		background: rgba(255,255,255,0.9);
		border-radius: 40px;
		box-shadow:0 1px 2px rgba(0,0,0,0.3);
	  display: none;
	  a {
	    text-decoration: none;
	    display: block;
	    text-align: center;
			color: $color-base;
			padding: 12px 0 15px;
			font-size: 1rem;
		  background-size: 35%;
	  }
	}
	@include mq('max', 'xs') {
		display: none !important;
	}
}

#localnav ul{
  padding-left: 0;
  margin-bottom: 0;
  li{
    font-size: .9rem;
    list-style: none;
    a{
      display: block;
      text-align: center;
      border:1px solid #ccc;
      margin-bottom: 10px;
      padding: 10px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}


.slick-dots{
	bottom:-20px;
	li{
		height: 10px;
		width: 10px;
		margin:0 3px;
		button{
			height: 10px;
			width: 10px;
			padding: 0;
			&::before{
				width: 10px;
				height: 10px;
				line-height: 10px;
			}
		}
	}
}

#contents{
	padding-top: 80px;
  #headline{
		#breadcrumb{
			font-size: .75rem;
			color: #707070;
			margin-bottom: 2rem;
			a{
				color: #707070;
				text-decoration: none;
			}
		}
    h1{
      font-size: 1.3rem;
			font-weight: bold;
      vertical-align: middle;
			line-height: 1.6;
			margin: .4rem 0;
			@include mq('min', 'lg'){
				font-size: 1.8rem
			}
    }
  }
  @include mq('max', 'lg') {
		padding-top: 65px;
		#headline{
			margin-bottom: 0;
		}
    #breadcrumb{
      padding-bottom:20px;
    }
  }
	h1{
		font-weight: bold;
	}
  h2{
    font-size: 1.8rem;
		line-height: 1.6;
		font-weight: bold;
		margin-bottom: 1rem;
		@include mq('max', 'sm') {
		  font-size: 1.4rem;
		}
		@include mq('max', 'xs') {
		  font-size: 1.2rem;
		}
		&.lead{
			font-feature-settings : "palt";
		}
  }
  h3{
    line-height: 1.5;
		font-weight: bold;
		font-size: 1.375rem;
  }
  p{
    line-height: 1.8;
  }
	dt, dd, th, td{
    line-height: 1.6;
  }
	dt{
		@include mq('min', 'md') {
			font-size: 1.1rem;
		}
	}
	dd{
		margin-bottom: .4rem;
	}
  li{
    font-size: .9rem;
    line-height: 1.8;
  }
	a{
		text-decoration: underline;
	}
  .btn a{
    background: #eee;
		display: block;
		text-decoration: none;
    &:hover, &.hover{
      background: $color-base;
      color: #fff;
			text-decoration: none;
    }
  }
  .viewmore{
		@include mq('max', 'md') {
			text-align: center;
		}
		a.btn{
			background: $color-base;
			color: #fff;
			font-weight: bold;
			padding:.8rem;
			text-decoration: none;
	    &:hover, &.hover{
		    background: #ccc;
				color: $color-base;
				text-decoration: none;
	    }
			@include mq('max', 'md') {
				display: block;
			}
	  }
	}
	#content-nav{
		a{
			display: block;
			background: #666;
			color:#fff;
			text-decoration: none;
			padding: 8px;
			border-radius: 5px;
			text-align: center;
			border: 1px solid #666;
			&:hover{
				background: #fff;
				color: #666;
			}
		}
		.archive{
			background: #ddd;
			padding: 15px;
			h3{
				font-size: 1rem;
			}
			.select-box{
				overflow: hidden;
				width: 100%;
				margin: .5em auto;
				text-align: center;
				position: relative;
				border: 1px solid #bbbbbb;
				border-radius: 2px;
				background: #ffffff;
				&::before {
					position: absolute;
					top: 1em;
					right: 0.9em;
					width: 0;
					height: 0;
					padding: 0;
					content: '';
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 6px solid #666666;
					pointer-events: none;
				}
				select {
					width: 100%;
					padding-right: 1em;
					cursor: pointer;
					text-indent: 0.01px;
					text-overflow: ellipsis;
					border: none;
					outline: none;
					background: transparent;
					background-image: none;
					box-shadow: none;
					-webkit-appearance: none;
					appearance: none;
					padding: 8px 38px 8px 8px;
					color: #666;
					&::-ms-expand {
					    display: none;
					}
				}
			}
		}
	}
}

.home{
	#feature{
		padding-top: 60px;
		@include mq('min', 'lg') {
			max-width: 960px;
			margin:0 auto;
		}
		@include mq('min', 'xl') {
			max-width: 1140px;
		}
		.slick-next{
			right: 5%;
			width: 30px;
			height: 50px;
			text-align: center;
			@media screen and ( min-width : 684px ){
				right: 17%;
			}
			&::before{
			  position: absolute;
			  bottom: 0;
				right:5px;
			  content: '\f054';
			  color: #fff;
			  font-family: fontAwesome;
				opacity: 1;
			}
		}
		.slick-prev{
			left: 5%;
			z-index: 999;
			width: 30px;
			height: 30px;
			text-align: center;
			@media screen and ( min-width : 684px ){
				left: 17%;
			}
			&::before{
			  position: absolute;
			  bottom: 0;
				left:5px;
			  content: '\f053';
			  color: #fff;
			  font-family: fontAwesome;
				opacity: 1;
			}
		}
		.slide{
			@include mq('min', 'lg') {
				height: 634px;
			}
			@include mq('min', 'xl') {
				height: 752px;
			}
			@include mq('max', 'lg') {
				height: 60vw;
			}
			@include mq('max', 'md') {
				height: 80vw;
			}
		}
	}
	#contents{
		padding-top: 0;
	}
  #main{
    #menu{
			ul{
				li{
					text-align: center;
					font-size: 1rem;
					a{
						text-decoration: none;
						padding: 8px;
						display: inline-block;
					}
				}
			}
    }
		#banners{
			.banner{
				a{
					display: inline-block;
					text-decoration: none;
					img{
						border:1px solid #ccc;
					}
				}
			}
		}
		#topics{
			h2{
				font-size: 1.2rem
			}
		}
    .section{
      p{
        margin-bottom: 0;
      }
    }
  }
}
.about{
	#contents{
		h1{
			font-size: 1.8rem
		}
		p{
			line-height: 1.9;
		}
	}
	#profile{
		p{
			font-size: .9rem;
		}
		img{
			width: 240px;
		}
	}
}
.office{
	#contents{
		h1{
			font-size: 1.8rem
		}
		p{
			line-height: 1.9;
		}
	}
	#outline{
		dl{
			padding-left: 1rem;
			border-left:4px solid #eee;
		}
	}
	#office{
		text-align: center;
		p{
			margin:.5rem 0 2rem;
			text-align: left;
			line-height: 1.8;
		}
	}
}
.works-top{
	#contents{
		.work{
			a{
				text-decoration: none;
				display: block;
				h2{
					font-size: 1.1rem;
					margin: .8rem 0 .3rem;
					line-height: 1.5;
					display: inline-block;
					vertical-align: middle;
					.keyword{
						display: inline-block;
						background: #666;
						color: #fff;
						padding: 0 4px;
						border: 1px solid #666;
						border-radius: 3px;
						font-size: .75rem;
						font-weight: normal;
						vertical-align: text-bottom;
						margin-left: .3rem;
						margin-bottom: -2px;
					}
				}
				&:hover{
					opacity: .8;
				}
			}
		}
	}
}
.works-detail{
	#contents{
		#main-image{
			img{
				width: 100%;
				height: auto;
			}
		}
		.more{
			display: none;
		}
		.readmore{
			text-align: center;
			border-top:1px dotted #666;
			position: relative;
			margin-top: 25px;
			cursor: pointer;
			p{
				margin-top: -20px;
			}
			span{
				display: inline-block;
				background: #fff;
				font-size: .8rem;
				padding: 10px 10px;
			}
		}
		#photos{
			text-align: center;
			p{
				margin:.3rem .5rem 2rem;
				font-size: .95rem;
				img{
					display: inline-block !important;
					margin-bottom:.3rem;
					max-width: 100%;
				}
			}
		}
	}
}
.flow{
	#contents{
		.block{
			h2{
				display: flex;
				justify-content: flex-start;
				font-weight: bold;
				font-size: 1.2rem;
				align-items: center;
				.num{
					font-weight: normal;
					margin-right: .9rem;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #aaa;
					color: #fff;
					border-radius: 50%;
					width: 45px;
					height: 45px;
					font-size: 1.5rem;
				}
			}
			p{
				margin-left: 58px;
			}
		}
	}
}
.blog{
	#contents{
		.entry{
			a{
				text-decoration: none;
				display: block;
				.thumb{
					background-color: #eee;
					background-image: url(/_assets/img/noimage.svg);
					background-size: cover;
					background-position: center;
					width: 100%;
					height: 75%;
				}
				img{
					&:nth-child(n+2){
						display: none;
					}
				}
				h2{
					font-size: 1.1rem;
					margin: .3rem 0 0;
				}
				.date{
					font-size: .7rem;
					color: #999;
				}
			}
		}
	}
}
.blog-detail{
	#contents{
		.entry-header{
			margin-bottom: 1rem;
			h1{
				font-size: 1.8rem;
				font-weight: bold;
			}
		}
		.entry-meta{
			font-size: .8rem;
		}
		.entry-body{
			img{
				display: block;
				margin:0 auto;
				max-width: 100%;
				height: auto;
			}
		}
	}
}
.contact{
	#address{
		p{
			line-height: 2;
		}
	}
	#notes{
		ul{
			margin-bottom: 4rem;
		}
		li{
			font-size: .75rem;
		}
	}
	.required{
		color:#c33;
		font-size: .75rem;
	}
	dd{
		.note{
			font-size: .8rem;
		}
	}
}
