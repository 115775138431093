@charset "UTF-8";
@keyframes animationOpacity {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

body {
  animation: animationOpacity 1.2s ease 0.15s;
  animation-fill-mode: both;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "HelveticaNeue",Helvetica,Arial,sans-serif;
  color: #333; }
  body a {
    color: #333; }
  body a:hover {
    color: #333; }
  body a.disable {
    pointer-events: none;
    cursor: default; }

.header {
  position: fixed;
  width: 100vw;
  z-index: 10000;
  background: #fff; }

.gnav {
  height: 60px;
  z-index: 1000;
  margin: 0 auto; }
  .gnav .logo {
    width: 260px; }
    .gnav .logo img {
      width: 260px; }
  @media screen and (min-width: 62.0625em) {
    .gnav {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .gnav .menu-trigger, .gnav .overlay {
        display: none !important; }
      .gnav ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0; }
        .gnav ul li {
          font-size: .95rem; }
          .gnav ul li a {
            padding: 10px 6px 5px;
            display: inline-block; } }
  @media screen and (min-width: 75.0625em) {
    .gnav ul li a {
      padding: 10px 15px 5px; } }
  @media screen and (max-width: 62em) {
    .gnav .menu-trigger {
      display: inline-block;
      width: 24px;
      height: 19px;
      vertical-align: middle;
      cursor: pointer;
      position: fixed;
      top: 20px;
      right: 20px;
      z-index: 1002;
      transform: translateX(0); }
      .gnav .menu-trigger span {
        display: inline-block;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #707070;
        transition: all .5s; }
        .gnav .menu-trigger span:nth-of-type(1) {
          top: 0; }
        .gnav .menu-trigger span:nth-of-type(2) {
          top: 8px; }
        .gnav .menu-trigger span:nth-of-type(3) {
          bottom: 0; }
      .gnav .menu-trigger.active {
        transform: translateX(0);
        z-index: 2000; }
        .gnav .menu-trigger.active span {
          background-color: #333 !important; }
          .gnav .menu-trigger.active span:nth-of-type(1) {
            transform: translateY(8px) rotate(-45deg); }
          .gnav .menu-trigger.active span:nth-of-type(2) {
            opacity: 0; }
          .gnav .menu-trigger.active span:nth-of-type(3) {
            transform: translateY(-8px) rotate(45deg); }
    .gnav .logo {
      top: 14px;
      left: 20px;
      margin: 0 auto;
      position: absolute; }
    .gnav ul {
      width: 100vw;
      height: 100%;
      position: fixed;
      left: 0;
      z-index: 1005;
      transform: translate(-100vw);
      transition: all .5s;
      overflow-x: hidden !important;
      opacity: 0;
      margin: 60px 0 5px;
      padding-top: 15px;
      background: #fff; }
      .gnav ul.open {
        transform: translateX(0);
        overflow: hidden;
        box-sizing: border-box;
        opacity: 1; }
        .gnav ul.open li {
          opacity: 1; }
      .gnav ul li {
        color: #333;
        opacity: 0;
        padding: 4px 15px;
        background: #fff; }
        .gnav ul li a {
          color: #333;
          display: block;
          text-decoration: none;
          padding: 5px 25px; }
        .gnav ul li dl {
          padding: 30px 25px 8px;
          line-height: 1.95; }
          .gnav ul li dl dt {
            margin-bottom: .3rem; }
          .gnav ul li dl a {
            padding: 0; }
    .gnav .overlay {
      content: "";
      display: block;
      width: 0;
      height: 0;
      background-color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1001;
      opacity: 0;
      transition: opacity .5s;
      top: 70px; }
      .gnav .overlay.open {
        width: 100%;
        height: 100%;
        opacity: 1; } }

footer {
  font-size: .7rem;
  margin: 3rem 0 1rem; }
  footer > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline; }
  footer img {
    width: 71px; }

@media screen and (min-width: 30.125em) {
  .to-page-top {
    z-index: 1100;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    text-align: center;
    background: #fff;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 40px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    display: none; }
    .to-page-top a {
      text-decoration: none;
      display: block;
      text-align: center;
      color: #333;
      padding: 12px 0 15px;
      font-size: 1rem;
      background-size: 35%; } }

@media screen and (max-width: 30.0625em) {
  .to-page-top {
    display: none !important; } }

#localnav ul {
  padding-left: 0;
  margin-bottom: 0; }
  #localnav ul li {
    font-size: .9rem;
    list-style: none; }
    #localnav ul li a {
      display: block;
      text-align: center;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      padding: 10px; }
      #localnav ul li a:hover {
        text-decoration: none; }

.slick-dots {
  bottom: -20px; }
  .slick-dots li {
    height: 10px;
    width: 10px;
    margin: 0 3px; }
    .slick-dots li button {
      height: 10px;
      width: 10px;
      padding: 0; }
      .slick-dots li button::before {
        width: 10px;
        height: 10px;
        line-height: 10px; }

#contents {
  padding-top: 80px; }
  #contents #headline #breadcrumb {
    font-size: .75rem;
    color: #707070;
    margin-bottom: 2rem; }
    #contents #headline #breadcrumb a {
      color: #707070;
      text-decoration: none; }
  #contents #headline h1 {
    font-size: 1.3rem;
    font-weight: bold;
    vertical-align: middle;
    line-height: 1.6;
    margin: .4rem 0; }
    @media screen and (min-width: 62.0625em) {
      #contents #headline h1 {
        font-size: 1.8rem; } }
  @media screen and (max-width: 62em) {
    #contents {
      padding-top: 65px; }
      #contents #headline {
        margin-bottom: 0; }
      #contents #breadcrumb {
        padding-bottom: 20px; } }
  #contents h1 {
    font-weight: bold; }
  #contents h2 {
    font-size: 1.8rem;
    line-height: 1.6;
    font-weight: bold;
    margin-bottom: 1rem; }
    @media screen and (max-width: 36em) {
      #contents h2 {
        font-size: 1.4rem; } }
    @media screen and (max-width: 30.0625em) {
      #contents h2 {
        font-size: 1.2rem; } }
    #contents h2.lead {
      font-feature-settings: "palt"; }
  #contents h3 {
    line-height: 1.5;
    font-weight: bold;
    font-size: 1.375rem; }
  #contents p {
    line-height: 1.8; }
  #contents dt, #contents dd, #contents th, #contents td {
    line-height: 1.6; }
  @media screen and (min-width: 48.0625em) {
    #contents dt {
      font-size: 1.1rem; } }
  #contents dd {
    margin-bottom: .4rem; }
  #contents li {
    font-size: .9rem;
    line-height: 1.8; }
  #contents a {
    text-decoration: underline; }
  #contents .btn a {
    background: #eee;
    display: block;
    text-decoration: none; }
    #contents .btn a:hover, #contents .btn a.hover {
      background: #333;
      color: #fff;
      text-decoration: none; }
  @media screen and (max-width: 48em) {
    #contents .viewmore {
      text-align: center; } }
  #contents .viewmore a.btn {
    background: #333;
    color: #fff;
    font-weight: bold;
    padding: .8rem;
    text-decoration: none; }
    #contents .viewmore a.btn:hover, #contents .viewmore a.btn.hover {
      background: #ccc;
      color: #333;
      text-decoration: none; }
    @media screen and (max-width: 48em) {
      #contents .viewmore a.btn {
        display: block; } }
  #contents #content-nav a {
    display: block;
    background: #666;
    color: #fff;
    text-decoration: none;
    padding: 8px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #666; }
    #contents #content-nav a:hover {
      background: #fff;
      color: #666; }
  #contents #content-nav .archive {
    background: #ddd;
    padding: 15px; }
    #contents #content-nav .archive h3 {
      font-size: 1rem; }
    #contents #content-nav .archive .select-box {
      overflow: hidden;
      width: 100%;
      margin: .5em auto;
      text-align: center;
      position: relative;
      border: 1px solid #bbbbbb;
      border-radius: 2px;
      background: #ffffff; }
      #contents #content-nav .archive .select-box::before {
        position: absolute;
        top: 1em;
        right: 0.9em;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #666666;
        pointer-events: none; }
      #contents #content-nav .archive .select-box select {
        width: 100%;
        padding-right: 1em;
        cursor: pointer;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 8px 38px 8px 8px;
        color: #666; }
        #contents #content-nav .archive .select-box select::-ms-expand {
          display: none; }

.home #feature {
  padding-top: 60px; }
  @media screen and (min-width: 62.0625em) {
    .home #feature {
      max-width: 960px;
      margin: 0 auto; } }
  @media screen and (min-width: 75.0625em) {
    .home #feature {
      max-width: 1140px; } }
  .home #feature .slick-next {
    right: 5%;
    width: 30px;
    height: 50px;
    text-align: center; }
    @media screen and (min-width: 684px) {
      .home #feature .slick-next {
        right: 17%; } }
    .home #feature .slick-next::before {
      position: absolute;
      bottom: 0;
      right: 5px;
      content: '\f054';
      color: #fff;
      font-family: fontAwesome;
      opacity: 1; }
  .home #feature .slick-prev {
    left: 5%;
    z-index: 999;
    width: 30px;
    height: 30px;
    text-align: center; }
    @media screen and (min-width: 684px) {
      .home #feature .slick-prev {
        left: 17%; } }
    .home #feature .slick-prev::before {
      position: absolute;
      bottom: 0;
      left: 5px;
      content: '\f053';
      color: #fff;
      font-family: fontAwesome;
      opacity: 1; }
  @media screen and (min-width: 62.0625em) {
    .home #feature .slide {
      height: 634px; } }
  @media screen and (min-width: 75.0625em) {
    .home #feature .slide {
      height: 752px; } }
  @media screen and (max-width: 62em) {
    .home #feature .slide {
      height: 60vw; } }
  @media screen and (max-width: 48em) {
    .home #feature .slide {
      height: 80vw; } }

.home #contents {
  padding-top: 0; }

.home #main #menu ul li {
  text-align: center;
  font-size: 1rem; }
  .home #main #menu ul li a {
    text-decoration: none;
    padding: 8px;
    display: inline-block; }

.home #main #banners .banner a {
  display: inline-block;
  text-decoration: none; }
  .home #main #banners .banner a img {
    border: 1px solid #ccc; }

.home #main #topics h2 {
  font-size: 1.2rem; }

.home #main .section p {
  margin-bottom: 0; }

.about #contents h1 {
  font-size: 1.8rem; }

.about #contents p {
  line-height: 1.9; }

.about #profile p {
  font-size: .9rem; }

.about #profile img {
  width: 240px; }

.office #contents h1 {
  font-size: 1.8rem; }

.office #contents p {
  line-height: 1.9; }

.office #outline dl {
  padding-left: 1rem;
  border-left: 4px solid #eee; }

.office #office {
  text-align: center; }
  .office #office p {
    margin: .5rem 0 2rem;
    text-align: left;
    line-height: 1.8; }

.works-top #contents .work a {
  text-decoration: none;
  display: block; }
  .works-top #contents .work a h2 {
    font-size: 1.1rem;
    margin: .8rem 0 .3rem;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle; }
    .works-top #contents .work a h2 .keyword {
      display: inline-block;
      background: #666;
      color: #fff;
      padding: 0 4px;
      border: 1px solid #666;
      border-radius: 3px;
      font-size: .75rem;
      font-weight: normal;
      vertical-align: text-bottom;
      margin-left: .3rem;
      margin-bottom: -2px; }
  .works-top #contents .work a:hover {
    opacity: .8; }

.works-detail #contents #main-image img {
  width: 100%;
  height: auto; }

.works-detail #contents .more {
  display: none; }

.works-detail #contents .readmore {
  text-align: center;
  border-top: 1px dotted #666;
  position: relative;
  margin-top: 25px;
  cursor: pointer; }
  .works-detail #contents .readmore p {
    margin-top: -20px; }
  .works-detail #contents .readmore span {
    display: inline-block;
    background: #fff;
    font-size: .8rem;
    padding: 10px 10px; }

.works-detail #contents #photos {
  text-align: center; }
  .works-detail #contents #photos p {
    margin: .3rem .5rem 2rem;
    font-size: .95rem; }
    .works-detail #contents #photos p img {
      display: inline-block !important;
      margin-bottom: .3rem;
      max-width: 100%; }

.flow #contents .block h2 {
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  font-size: 1.2rem;
  align-items: center; }
  .flow #contents .block h2 .num {
    font-weight: normal;
    margin-right: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #aaa;
    color: #fff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    font-size: 1.5rem; }

.flow #contents .block p {
  margin-left: 58px; }

.blog #contents .entry a {
  text-decoration: none;
  display: block; }
  .blog #contents .entry a .thumb {
    background-color: #eee;
    background-image: url(/_assets/img/noimage.svg);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 75%; }
  .blog #contents .entry a img:nth-child(n+2) {
    display: none; }
  .blog #contents .entry a h2 {
    font-size: 1.1rem;
    margin: .3rem 0 0; }
  .blog #contents .entry a .date {
    font-size: .7rem;
    color: #999; }

.blog-detail #contents .entry-header {
  margin-bottom: 1rem; }
  .blog-detail #contents .entry-header h1 {
    font-size: 1.8rem;
    font-weight: bold; }

.blog-detail #contents .entry-meta {
  font-size: .8rem; }

.blog-detail #contents .entry-body img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto; }

.contact #address p {
  line-height: 2; }

.contact #notes ul {
  margin-bottom: 4rem; }

.contact #notes li {
  font-size: .75rem; }

.contact .required {
  color: #c33;
  font-size: .75rem; }

.contact dd .note {
  font-size: .8rem; }
